<template>
  <div style="height:calc(100vh - 40px);overflow-y:auto;width: 75%">
    <el-form :inline="true" :model="searchForm">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="6" :md="24">
            <el-form-item label="商户名称：">
              <el-input
                clearable
                v-model="searchForm.merchantName"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="6" :md="24">
            <el-form-item label="课程账号类型：">
              <el-select
                clearable
                v-model="searchForm.catalogId"
                placeholder="状态"
                class="w100"
              >
                <el-option
                  v-for="item in courseCatalogList"
                  :key="item.id"
                  :label="item.courseGoodsName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <span>
            <el-button @click="resetForm">重置</el-button>
            <el-button type="primary" @click="getListHandler">查询</el-button>
          </span>
        </el-row>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-card >
      <div slot="header" class="flex" style="align-items: center">
        <span>订单数据</span>
        <div class="flex_1"></div>
        <el-button type="primary" size="mini" @click="openAddModal"
          >购买课时</el-button>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table
        :default-sort="{ prop: 'open_date', order: 'descending' }"
        :key="tableKey"
        fit
        :highlight-current-row="true"
        :data="tableData"
      >
        <el-table-column
          v-for="item of defaultHeader"
          :key="item.label"
          :label="item.label"
          :align="item.align ? item.align : 'left'"
          :min-width="item.width ? item.width : 'auto'"
          :sortable="item.sortable ? item.sortable : false"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <template v-if="item.label === '状态'">
              <el-popconfirm
                @onConfirm="confirmStatus(scope.row)"
                @confirm="confirmStatus(scope.row)"
                :title="`确定要${
                    statusOptions[scope.row.status == '正常' ? '禁用' : '正常']
                  }该数据吗？`"
              >
                <el-button type="text" slot="reference">
                  {{ statusOptions[scope.row.status] }}
                </el-button>
              </el-popconfirm>
            </template>
            <template v-else-if="item.label === '操作'">
              <el-button
                style="margin-left: 8px"
                type="text"
                ref="openDetail"
                @click="openDetailModal(scope.row)"
              >查看教师</el-button
              >
              <el-button
                style="margin-left: 8px"
                type="text"
                ref="openDetailadMinistrators"
                @click="openDetailadMinistrators(scope.row)"
              >查看管理员</el-button
              >
              <el-button
                style="margin-left: 8px"
                type="text"
                @click="openEditModal(scope.row)"
              >编辑</el-button
              >
            </template>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>

    <add-purchase
      ref="add"
      title="购买课时"
      type="primary"
    ></add-purchase>
  </div>
</template>
<script>
import {
  getMerchantList,
  $addMerchant,
  $editMerchant,
  $forbiddenMerchant,
  // $getInfo,
  // $deleteEmployee,
  forbiddenMerchant
} from "@/api/member/merchant";
import {
  courseCatalog
} from '@/api/research/curriculum';
import addPurchase from "@/components/merchantInfo/addPurchase";

import DetailModal from "@/views/member/DetailModal";
import TextButton from "@/components/TextButton";
// 默认展示列

const baseColSetting = [
  {
    label: "商户名称",
    prop: "merchantName",
    state: true,
    align: "left",
    width: "50px",
  },
  {
    label: "课程账号类型",
    prop: "state",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "创建时间",
    prop: "createTime",
    state: true,
    sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "课时数量",
    state: true,
    align: "center",
    width: "150",
  },
];
export default {
  components: { addPurchase, DetailModal, TextButton },
  data() {
    return {
      tableKey: 1,
      item: {},
      searchForm:{},
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      title: "",
      size: "small",
      tableData: [],
      courseCatalogList:[],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  created() {
    // this.getListHandler();
    this.courseCatalog()
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    /**
     * 获取课程账号类型
     */
    async courseCatalog() {
      let responseData = await courseCatalog(
        {
          pageNum: 1,
          pageSize: 1000,
          needCount: true
        }
      );
      if (responseData && responseData.state == "success") {
        this.courseCatalogList = responseData.body.list;
      }
    },
    async getListHandler() {
      let responseData = await getMerchantList(
        {
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        ...this.searchForm,
        }
      );
      console.log(responseData)
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
    /**
     * 获取详情
     */
    async getDetail(id) {
      return this.tableData.filter((item) => item.id == id)[0];
    },
    confirmStatus(item) {
      const { id, state } = item;
      forbiddenMerchant({
        id,
        merchantStatus: state == "正常" ? "禁用" : '正常',
      }).then(() => {
        this.getListHandler();
      });
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.searchForm = {
        loginName: null,
        name: null,
        telNumber: null,
        merchantStatus: null,
        merchantName: null,
      };
      this.getListHandler();
    },
    /**
     * 查看
     */
    handleClick(item) {},
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    /**
     * 打开编辑弹窗
     */
    openEditModal(item) {
      // TODO 添加获取详情
      this.item = item || {};
      this.$nextTick(() => {
        this.$refs.edit.dialogVisible = true;
      });
    },
    /**
     * 打开添加弹窗
     */
    openAddModal() {
      this.$refs.add.dialogVisible = true;
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}
@import "../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>

